import styled from 'styled-components'
import * as palette from '../styles/variables';
import { device, width } from '../styles/device';
import {BreadCrumbStyled} from '../components/breadCrumbStyled'




export const PostStyled = styled.div`
    margin-top: ${palette.headerTabletHeight};
    background-color: ${palette.lightBg};

    @media ${device.tablet} {
        margin-top: ${palette.headerWithTopHeight};
    }

    .content-wrapper {
        padding: 2rem 15px;

        .section-wrapper {
            width: 100%;

            .section-inner {
                margin: 0 auto;
                max-width: ${width.lg};
                background-color: ${palette.white};
                padding: 2rem 15px;
                animation: bounce-in-right 1s;
                box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);

                @media ${device.laptop} {
                    padding: 5rem 3rem;
                }

                .section {

                    .post-image {

                        .imageContainer {
                            width: 100%;

                            img {
                                max-height: 500px;
                                object-fit: contain;
                            }
                        }

                    }

                    .dateWrapper {
                        font-size: .8rem;
                        color: ${palette.ctaLight};
                        display: flex;
                        align-items: center;
                        margin-bottom: .5rem;

                        svg.postCard {
                            width: 15px;
                            height: 15px;
                            margin-right: 10px;

                            .group-1 {
                                path, circle {
                                    fill: ${palette.ctaLight};
                                }
                            }
                        }
                    }

                    .post-title {
                        margin-top: 2rem;

                        .title {
                            color: ${palette.cta};
                            line-height: 2.2rem;
                        }
                    }

                    .post-detail {

                        p {
                            i {
                                .bold {
                                    font-weight: 700;
                                    color: ${palette.redDanger};
                                    font-style: normal;
                                }
                            }

                            .bold {
                                font-weight: 700;
                                color: ${palette.cta};
                            }
                        }

                        .detailWrapper {
                            margin-top: 1rem;

                            .imageWrapper {
                                max-width: 600px;
                                margin: 0 auto;

                                img {
                                    margin-top: .8rem;
                                }
                            }
                        }


                    }

                }


            }
        }

    }

    @keyframes bounce-in-right {
        0% {
            opacity: 0;
            transform: translateX(2000px);
        }
        60% {

            transform: translateX(-30px);
        }
        80% {
            transform: translateX(10px);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }

`;


export const BreadCrumbInnerStyled = styled(BreadCrumbStyled)`
    padding-left: 0;
    padding-right: 0;

    .postSlug {
       margin-top: 0;
       padding-top: 2rem;

       @media ${device.desktop} {
            padding-top: 2rem;
       }
    }
`