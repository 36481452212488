import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Img from 'gatsby-image'
import {PostStyled, BreadCrumbInnerStyled} from './postStyled'
import Markdown from 'react-markdown'
import BreadCrumb from '../components/breadCrumb'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import DateIcon from '../components/icons/dateIcon'


const Bold = ({ children }) => <span className="bold">{children}</span>;
const Text = ({ children }) => <p className="align-center">{children}</p>;

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { file, title } = node.data.target.fields
      return file && <div className="imageWrapper"><img src={file['en-US'].url} alt={title['en-US']} className="image" /></div>
    },
  },
};

class PostSlug extends Component {
    render() {
        const { title, text, detail, imageUrl, category, date } = this.props.data.contentfulPosts
        const descriptionMeta = text.text ? text.text : 'Savie Glove'
        const foundCate = category && category.find(element => element.name);
        const foundCateTitle = category && category.find(element => element.title);
        return (
          <Layout hideContact={false} backgroundColor={false} showMarketingPopup={true} >
            <SEO title={title} description={descriptionMeta} />
              <PostStyled>
                <BreadCrumbInnerStyled>
                  <BreadCrumb className="postSlug" slug={foundCate && foundCate.name} breadCrumbTitle={title} categoryTitle={foundCateTitle && foundCateTitle.title} />
                </BreadCrumbInnerStyled>
                <div className="content-wrapper">
                  <div className="section-wrapper">
                      <div className="section-inner">
                        <div className="section">
                          {date && <div className="dateWrapper"><DateIcon name="postCard"/>{date}</div>}
                          {imageUrl && <div className="post-image">
                            <div className="imageContainer">
                              <img src={imageUrl && imageUrl} />
                            </div>
                          </div>}
                          {title && <div className="post-title"><h2 className="title">{title}</h2></div>}
                          {detail && <div className="post-detail">
                            <div className="detailWrapper">
                            {
                              documentToReactComponents(detail.json, options)
                            }
                            </div>
                        </div>}
                      </div>
                    </div>
                  </div>
                </div>
              </PostStyled>
            </Layout>
        )
    }
}

PostSlug.propTypes = {
    data: PropTypes.object.isRequired
}

export default PostSlug;

export const pageQuery = graphql`
  query PostsBySlug($slug: String!) {
    contentfulPosts(slug: { eq: $slug }) {
        date
        id
        title
        imageUrl
        text {
          text
        }
        detail {
          json
        }
        category {
          name
          title
        }
      }
  }
`